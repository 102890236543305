import { Container, Grid, Button } from '@mui/material'
import { useState, useEffect} from 'react'
import { Fade, Slide } from 'react-reveal'
import { MediaHelmet } from '../../HelmetTags';

export const Swapper = ({ data }) => {
    const [ selected, setSelected ] = useState(0)
    const [ fadein, setFadeIn ] = useState(false)

    const handleSelected = (item) => { setSelected(item); setFadeIn(false) }

    useEffect(() => {
        setFadeIn(true)
    }, [selected])

    useEffect(() => {
        setTimeout(() => {setFadeIn(true)}, 1000)
    }, [])

    return (
        <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}} >
        <Grid container style={{marginBottom: '100px'}}>
            <Grid item xs={12} md={12} style={{textAlign: 'center'}}>
                <Fade in={fadein}>
                    <p style={{textAlign:'center', padding: '10px', lineHeight: '1.8em'}} className='dynHeight'>{ data[selected].data }</p>
                </Fade>
                <div style={{display: 'flex', justifyContent: 'center'}}>
                <Slide in={fadein} left>
                <Button onClick={() => handleSelected(data[selected].goto)} variant="contained"
                    sx={{ zIndex: '999', textTransform: 'uppercase', letterSpacing: '2px', my: 2, mx: 2, bgcolor: 'transparent', borderRadius: '0', border: '1px solid #FFF3', paddingLeft: '15px', marginBottom: '30px', paddingRight: '15px',  color: 'white', display: 'block', ':hover': {
                        bgcolor: '#FFF1', // theme.palette.primary.main
                        color: 'white', boxShadow: '1px 1px 3px #bbb', 
                    } }}>{ data[selected].gototitle }</Button>
                </Slide>
                </div>
            </Grid>
        </Grid>
        </div>
    )
}

export const ExecutiveMediaPage = (props) => {   
    useEffect(() => {
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'})
    }, [])

    return (
        <Container style={{paddingBottom: '120px'}}>

            { MediaHelmet }
            <div>
                <Fade>
                <Container>
                    <Grid container>
                        <Grid item xs={12} md={12}>
                            <h1 className='lightBlueText centered'>Executive Communications Training</h1>
                        </Grid>
                    </Grid>
                    <Swapper data={ MediaSwapper } />
                </Container> 
                </Fade>
            </div>

            <div>
                <img src={process.env.PUBLIC_URL + '/images/reporter.webp'} alt="reporter" className='reportBG' style={{overflow: 'hidden', width: '100%'}} />
            </div>

            <Fade>
                <div style={{borderLeft: '1px solid #fff3',borderRight: '1px solid #fff3'}}>
                    <Container style={{padding: '10px', lineHeight: '1.8em'}}>
                        <h1 className='lightBlueText centered'>Our Approach</h1>
                        <h3>Our former national-level print and broadcast journalists offer:</h3>
                        <ul>
                            <li>Customized training to fit your needs</li>
                            <li>Individual or group sessions</li>
                            <li>Realistic on-camera interview training</li>
                            <li>Zoom remote interview training</li>
                            <li>Narrative and message optimization</li>
                        </ul>
                        <p className='centered'>We'll ask the same kinds of questions you can expect from journalists and give you the immediate feedback you need to improve.</p>
                    </Container>
                </div>
            </Fade>
            <Grid container>
                <Grid item xs={12} md={6} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    {/*<ModelTrainingIcon sx={{width: '100px', height: '100px'}}/>*/}
                </Grid>
                <Grid item xs={12} md={6} style={{padding: '10px', lineHeight: '1.8em'}}>
                    <Slide up cascade>
                    <h1 className='lightBlueText centered'>What You'll Learn</h1>
                    <ul style={{marginLeft: '0px'}}>
                        { MediaLearn.map((item) => {
                            return (
                                <li key={item}>{ item }</li>
                            )
                        })}
                    </ul>

                    </Slide>
                </Grid>
            </Grid> 

            <Fade in={true} timeout={1000}>
                <div style={{zIndex: '1'}}>
                    <img src={process.env.PUBLIC_URL + "/images/services-image.webp"} alt={'collective'} className={"imageBase rotating fadeinout"} style={{minHeight: '500px', minWidth: '633px', overflowX: 'hidden'}} />
                </div>
            </Fade>

            <Grid container style={{marginTop: '50px'}}>
                <Grid item xs={12} md={12} style={{paddingTop: '50px'}}>
                    <Fade>
                    <h1 className='lightBlueText centered'>The Next Solutions Group Difference</h1>
                    </Fade>
                </Grid>

                <Grid item xs={12} md={12} style={{padding: '10px', display: 'flex', justifyContent: 'center', flexDirection: 'column'}}>
                    <Fade>
                    <ul style={{fontSize: '1.1em', lineHeight: '1.7em', paddingLeft: '0'}}>
                        <li>{ MediaDifference[0] }</li>
                        <hr style={{opacity: '0.5'}}/>
                        <li>{ MediaDifference[1] }</li>
                        <hr style={{opacity: '0.5'}}/>
                        <li>{ MediaDifference[2] }</li>
                    </ul>
                    </Fade>
                </Grid>

            </Grid>

            <div className='divideranim ' />

            <Grid container style={{marginTop: '200px'}}>
                <Grid item xs={12} md={12}>
                    <Grid container>
                        <Grid item xs={12} md={4} style={{padding: '10px'}} order={{xs: 1, md: 1}}>
                            <Fade>
                            <img src={process.env.PUBLIC_URL + '/images/people/justinb.webp'}  alt={"Justin Blum"} style={{width: '100%'}} />
                            </Fade>
                        </Grid>
                        <Grid item xs={12} md={8} order={{xs: 2, md: 2}} style={{maxWidth: '100%', lineHeight: '1.5em', display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                            <Slide up>
                            <p><b className='lightBlueText'>Justin Blum. </b> Former senior editor at Bloomberg News. Former reporter at Bloomberg, the Washington Post and St. Petersburg Times (now Tampa Bay Times.)</p>
                            </Slide>
                        </Grid>

                        <Grid item xs={12} md={8} order={{xs: 4, md: 3}} style={{maxWidth: '100%', lineHeight: '1.5em', display: 'flex', justifyContent: 'center', alignItems:'center'}} >
                            <Slide up>
                            <p><b className='lightBlueText'>Dan Childs. </b>Former managing editor of the ABC News medical unit. Former SVP of healthcare media relations at Porter Novelli and director of U.S. external communications for Bayer.</p>
                            </Slide>
                        </Grid>
                        <Grid item xs={12} md={4} style={{padding: '10px'}} order={{xs: 3, md: 4}}>
                            <Fade>
                            <img src={process.env.PUBLIC_URL + '/images/people/dan.webp'} alt={"Dan Childs"} style={{width: '100%'}} />
                            </Fade>
                        </Grid>

                        <Grid item xs={12} md={4} style={{padding: '10px'}} order={{xs: 5, md: 5}}>
                            <Fade>
                            <img src={process.env.PUBLIC_URL + '/images/people/ray.webp'}  alt={"Ray Kerins"} style={{width: '100%'}} />
                            </Fade>
                        </Grid>
                        <Grid item xs={12} md={8} order={{xs: 6, md: 6}} style={{maxWidth: '100%', lineHeight: '1.5em', display: 'flex', justifyContent: 'center', alignItems:'center'}}>
                            <Slide up>
                            <p><b className='lightBlueText'>Ray Kerins. </b>Decades of experience preparing corporate executives for media engagements while leading communications for Bayer, Pfizer, and Merck.</p>
                            </Slide>
                        </Grid>

                    </Grid>
                </Grid>
            </Grid>
        </Container>
            

    )
}

// Variables needed for page build 

const MediaLearn = [
    'Answering hard-hitting questions with ease',
    'What reporters need when they interview you',
    'How to effectively deliver your message',
    'Turn your key points into sound bites',
    'Understanding what constitutes newsworthy comments',
    'Use of body language for TV, webcams and in-person interviews',
    'Staying on message',
    `What you can and can't talk about`,
]

const MediaDifference = [
    'Our team brings decades of top-tier media and Fortune 500 corporate experience',
    'We custom-tailor our trainings to the reputational landscape that your company and executives face',
    `We stand ready to jump in at a moment's notice and prepare executives to speak to reporters when a crisis strikes`,
]


const MediaSwapper = [
    {
        data: "Learn how to effectively present your message internally and externally. Master the ability to answer questions from reporters who interview you one-on-one, in news conferences, and in remote studios. We'll coach you on how to get your points across even as you're asked questions designed to knock you off your talking points. We'll give you the confidence you need to succeed.", gototitle: 'Learn', goto: 1,
    },
    {
        data: "What sets us apart: Our experts are former print, digital, and TV journalists. We know what questions you'll be asked. We have prepared C-suite executives, board members and front-line workers for interviews on network television and radio, with the largest newspapers in the U.S. and overseas, and on podcasts.", gototitle: 'Experience', goto: 0,
    },
]
