import { Container, Grid } from "@mui/material"
import { useEffect} from 'react'
import { Fade } from "react-reveal"


export const MediaEngagementPage = () => {
    useEffect(() => {
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'})
    }, [])
    return (
        <div style={{paddingBottom: '120px'}}>
            <Container>
                <Grid container padding={2}>
                    <Grid item xs={12} md={12}>
                        <Fade>
                            <h1>Media Engagement</h1>
                            <div>
                                Our team understands what makes a story. We'll help develop a narrative that will appeal to print and broadcast outlets and pitch your story. We also work with organizations facing issues and crises to provide rapid responses to questions from reporters and ensure your message gets heard.
                            </div>
                            <div style={{textAlign: 'center', paddingTop: '50px'}}>
                            For more information, contact us at <a href="mailto:engage@nsg-global.com" className="lightBlueText" >engage@nsg-global.com</a>
                            </div>
                        </Fade>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}