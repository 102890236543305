import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid,  IconButton, Pagination } from '@mui/material'

import { useEffect, useRef, useState } from 'react'
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { AllThoughtPosts } from '../../thoughts/thoughtleadership';
import { Fade } from 'react-reveal';
//import { Fade } from 'react-reveal';
//import { Fade } from 'react-reveal';
const AmountPerPage = 9;

export const ThoughtLeadership = () => {
    const asRef = useRef((pn) => {
        const startI = (pn-1) * AmountPerPage;
        return AllThoughtPosts.slice(startI, startI+AmountPerPage)
    })
    

    const [ tlPiece, setTlPiece ] = useState(undefined)
    const [ open, setOpen ] = useState(false)
    const [ activeShowPieces, setActiveShown ] = useState(asRef.current(1))
    const [ curPage, setCurPage ] = useState(1)
    const [ pageCount, setPageCount ] = useState(1)
    const handlePageChange = (e, val) => {
        if (val !== curPage) {
            document.getElementById("topPage").scrollIntoView()
            setCurPage(val)
        }
    }

    const handleSetPiece = async (piece) => {
        setTlPiece(piece)
        handleDialogOpen()
    }
    const handleDialogOpen = () => setOpen(true)
    const handleDialogClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        setActiveShown(asRef.current(curPage))
    }, [curPage])

    // top scroll tl & onload for page numbers
    useEffect(() => {
        // get amount of pages
        var pages = Math.floor(AllThoughtPosts.length / AmountPerPage)
        var mod = (AllThoughtPosts.length % AmountPerPage)
        if (mod !== 0) {
            pages++
        }
        setPageCount(pages)
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'})
    }, [])

    return (
        <div style={{paddingBottom: '120px'}}>
            <Container>
                <h1 id="topPage">Thought Leadership</h1>

                <Grid container columnSpacing={2} rowSpacing={2} style={{justifyContent: 'center'}}>
                    { activeShowPieces.map((item, i) => (
                        <Grid item xs={12} md={4} style={{ minHeight: '300px'}} key={`item-${i}`}>
                            <Fade>
                            <div 
                                onClick={() => handleSetPiece(item)}
                                className='tlHover'
                                style={{
                                    zIndex: '9',
                                    height: '300px',
                                    padding: '10px',
                                    border: '1px solid #777',
                                    borderRadius: '10px',
                                }}>
                                { /*<div style={{fontSize: '0.9rem', textAlign: 'right', borderBottom: '1px solid #777', marginBottom: '10px'}}>{ item.date }</div> */}
                                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', minHeight: '275px'}}>
                                    <div style={{textAlign: 'center'}}>{ item.title }</div>
                                    <div style={{padding: '10px'}}>
                                        <img 
                                            alt={item.title}
                                            style={{maxWidth: '100%', maxHeight: '150px'}}
                                            src={process.env.PUBLIC_URL + `/images/thoughtleadership/${item.images[0]}`} />
                                    </div>
                                </div>
                            </div>
                            </Fade>
                        </Grid>
                    ))}
                </Grid>

                <div style={{
                        backgroundColor: '#fff',
                        color: '#000',
                        padding: '5px',
                        borderRadius: '5px',
                        justifyContent: 'center',
                        marginTop: '40px' 
                    }}>
                    <div>
                        <Pagination 
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                            page={curPage}
                            onChange={handlePageChange}
                            color='primary'
                            count={pageCount} variant="outlined" shape="rounded" />
                    </div>
                </div>

                <Dialog
                    open={open}
                    onClose={handleDialogClose}
                    >
                    <DialogTitle className='tlDialog'>{ tlPiece?.title }</DialogTitle>
                    <DialogContent className='tlDialog'>
                        { /*<p style={{fontSize: '0.8rem'}}>{ tlPiece?.date }</p>*/}
                        <div>
                            <img 
                                alt={tlPiece?.title}
                                style={{maxWidth: '100%'}}
                                src={process.env.PUBLIC_URL + `/images/thoughtleadership/${tlPiece?.images[0]}`} />
                        </div>
                        <p style={{whiteSpace: 'pre-line', verticalAlign: 'bottom'}}>{ tlPiece?.content }</p>
                        <div style={{display: 'flex', justifyContent: 'flex-end', padding: '10px'}}>
        
                        </div>
                    </DialogContent>
                    <DialogActions className='tlDialog' sx={{
                            style: {
                                width: '100%',
                                display: 'flex', 
                                flexDirection: 'row', 
                                alignItems: 'center',
                                justifyContent: 'space-between'
                            }}}>
                        <div style={{flexGrow: '1'}}>
                            <div>
                                <IconButton style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px'}}>
                                    <a href={tlPiece?.linkedin} target='_blank' rel="noreferrer" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: '40px', height: '40px'}} >
                                        <LinkedInIcon style={{color: '#fff', width: '30px', height: '30px'}} />
                                    </a>
                                </IconButton>
                            </div>
                        </div>
                        <div>
                            <Button variant="contained" onClick={handleDialogClose}>Close</Button>
                        </div>
                    </DialogActions>
                </Dialog>
            </Container>
        </div>
    )

}
