import { useEffect} from 'react'
import { Fade } from '@mui/material'
import { CareersHelmet } from '../HelmetTags';

export const Careers = (props) => {
    useEffect(() => {
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'})
    }, [])

    return (
        <div style={{paddingBottom: '120px'}}>
            { CareersHelmet }
            <Fade in={true} timeout={800}>
            <div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                <h1>Careers</h1>
            </div>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <a href="mailto:careers@nsg-global.com" style={{textDecoration: 'none', color: '#4DA8DA'}}>careers@nsg-global.com</a>
            </div>
            </div>
            </Fade>
        </div>
    )
}


