import { Container, Grid } from "@mui/material"
import { useEffect} from 'react'
import { Fade } from "react-reveal"


export const CorporateSponsorshipsPage = () => {
    useEffect(() => {
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'})
    }, [])

    return (
        <div style={{paddingBottom: '120px'}}>
            <Container>
                <Grid container padding={2}>
                    <Grid item xs={12} md={12}>
                        <Fade>
                            <h1>Corporate Sponsorships</h1>
                            <div>
                                We identify sponsorships and partnerships that advance companies' reputation in a way that aligns with their values. We help clients identify key audiences and reach them with sponsorships in a way that focuses on shared purpose, not transactional relationships. We work with sports franchises, major international events, national and international media organizations, NGOs and leading media outlets.
                            </div>
                            <div style={{textAlign: 'center', paddingTop: '50px'}}>
                                For more information, contact us at <a href="mailto:engage@nsg-global.com" className="lightBlueText" >engage@nsg-global.com</a>
                            </div>
                        </Fade>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}