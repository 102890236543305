import { Container, Grid } from "@mui/material"

export const RecognitionComponent = () => {

    return (
        <>
            <div className='bgChangeColor' style={{borderTop: '1px solid #555', display: 'flex', flexDirection: 'column', paddingTop: '20px', paddingBottom: '20px', backgroundColor: '#070e12', marginTop: '40px'}}>
                <Container>
                    <a href="https://observer.com/list/top-crisis-reputation-management-pr-firms-2024/#next-solutions-group" target="_blank" rel="noreferrer" style={{textDecoration: 'inherit', color: 'inherit'}}>
                        <Grid container>
                            { /** https://observer.com/list/top-crisis-reputation-management-pr-firms-2024/#next-solutions-group */}
                            <Grid item xs={12} md={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                                <div>
                                    <img src={process.env.PUBLIC_URL + '/images/Reputation_Management.webp'} alt="Reputation Management" style={{borderRadius: '10px',maxWidth: '300px'}} />
                                </div>
                            </Grid>
                            <Grid item xs={12} md={8} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold'}}>
                                <div style={{fontSize: '1.3rem', lineHeight: '2rem', textAlign: 'center', paddingTop: '15px', letterSpacing: '1.3px'}}>The Top PR Firms for Crisis & Reputation Management</div>
                            </Grid>
                        </Grid>
                    </a>
                </Container>
            </div>
            <div style={{borderTop: '1px solid #555'}}>
                <Container style={{paddingTop: '20px', paddingBottom: '20px'}}>
                    <a href="https://www.businessmanagementreview.com/the-next-solutions-group-nsg" target="_blank" rel="noreferrer" style={{textDecoration: 'inherit', color: 'inherit'}}>
                        <Grid container>
                            { /** https://www.businessmanagementreview.com/the-next-solutions-group-nsg */}
                            <Grid item xs={12} md={8} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold'}} order={{xs: 2, sm: 1}}>
                                <div style={{fontSize: '1.3rem', lineHeight: '2rem', textAlign: 'center', paddingTop: '15px', letterSpacing: '1.3px'}}>Top Crisis Communications Service Providers List</div>
                            </Grid>
                            <Grid item xs={12} md={4} style={{display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '10px'}} order={{xs: 1, sm: 2}}>
                                <div style={{backgroundColor: '#fff', padding: '15px', borderRadius: '10px'}}>
                                    <img src={process.env.PUBLIC_URL + '/images/BMR.webp'} alt="Business Management Review" style={{maxWidth: '300px'}} />
                                </div>
                            </Grid>

                        </Grid>
                    </a>
                </Container>
            </div>
        </>
    )
}