import { Container, Grid } from "@mui/material"
import { useEffect} from 'react'
import { Fade } from "react-reveal"

export const CrisisAndIssuesPage = () => {
    useEffect(() => {
        window.scrollTo({left: 0, top: 0, behavior: 'smooth'})
    }, [])

    return (
        <div style={{paddingBottom: '120px'}}>
            <Container>
                <Grid container padding={2}>
                        <Grid item xs={12} md={12}> 
                            <Fade>
                            <h1>Crisis & Issues</h1>
                            </Fade>
                        </Grid>
                        <Grid item sm={12} md={6}>
                            <Fade>
                            <ul style={{fontSize: '1.1em', lineHeight: '1.4em'}}>
                                { IssueList.map(item => (
                                    <li key={item}>{ item }</li>
                                ))}
                            </ul>
                            </Fade>
                        </Grid>
                        <Grid item sm={12} md={6} order={{sm: 1, md: 2}} style={{textAlign: 'center'}}>
                            <Fade>
                            <img src={process.env.PUBLIC_URL + "/images/services-image2.webp"} alt={'collective'} style={{maxWidth: '600px'}} className={"imageBase"} />
                            </Fade>
                        </Grid>
                    </Grid>
            </Container>
        </div>
    )
}

const IssueList = [
    "Litigation Communications",
    "Mergers & Acquisitions",
   "Product Recalls",
    "Congressional Investigations",
    "Government Inquiries",
    "Criminal Investigations",
    "Executive Issues",
]