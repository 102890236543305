import './App.css';
import './fonts/Roboto-Regular.ttf';

import { useState, useRef } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { Container, Fade, Snackbar, Alert, createTheme } from '@mui/material'
import { Home } from './components/pages/Home'
import { What } from './components/pages/What'
import { Who } from './components/pages/Who'
import { Contact } from './components/pages/Contact'
import { Careers } from './components/pages/Careers'
import  { NavBar } from './components/Navbar'
import { Footer } from './components/Footer'
import CookieConsent from "react-cookie-consent";
import { ThemeProvider as MuiThemeProvider } from '@mui/styles';
import { TestQRPage } from './components/pages/TestQr';
import { ThoughtLeadership } from './components/pages/ThoughtLeadership';
import { EditorialPage } from './components/pages/addedpages/EditorialPage';
import { CrisisAndIssuesPage } from './components/pages/addedpages/CrisisAndIssues';
import { MediaEngagementPage } from './components/pages/addedpages/MediaEngagement';
import { BusinessIntelligencePage } from './components/pages/addedpages/BusinessIntelligence';
import { CyberSecurityPage } from './components/pages/addedpages/CyberSecurity';
import { PublicAffairsPage } from './components/pages/addedpages/PublicAffairs';
import { CorporateSponsorshipsPage } from './components/pages/addedpages/CorporateSponsorships';
import { ExecutiveMediaPage } from './components/pages/addedpages/ExecutiveMedia';
import { MSAuthProvider } from './components/pages/TestAuth';



const MUItheme = createTheme({
  palette: {
    primary: {
      light: '#757ce8',
      main: '#0a233f',
      dark: '#002884',
      contrastText: '#fff',
      backgroundColor: '#000'
    },
    secondary: {
      light: '#ff7961',
      main: '#f44336',
      dark: '#ba000d',
      contrastText: '#000',
    },
    background: {
      default: '#040303',
    },
  },
});

function App({pca}) {
  const [snackbarOpened, setsnackbarOpened] = useState(false)
  const [cookiebarFade, setcookiebarFade] = useState(true)
  const fadeRef = useRef();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setsnackbarOpened(false);
  };


  return (
    <MuiThemeProvider theme={MUItheme}>
      <Router>
        <MSAuthProvider>
        <NavBar />
        {/**
        <Container style={{paddingTop: '20px', borderTop: '1px solid #FFF3'}} id="container"> */}
        { /* Change language button */ }

        <Fade in={true}>
        <div ref={fadeRef}>
        <Routes>
          { /* Base Website Landing Page */}
          <Route path="/" element={<Home />}  />
          <Route path="/what" element={<What />}  />
          <Route path="/who" element={<Who />}  />
          { /*<Route path="/whoadvisors" element={<WhoAdvisors />}  />*/}
          <Route path="/contact" element={<Contact />} />
          <Route path="/careers" element={<Careers />}  />

          { /* Added Pages */ }
          <Route path="/nsg/crisis-and-issues" element={<CrisisAndIssuesPage />} />
          <Route path="/nsg/media-engagement" element={<MediaEngagementPage />} />
          <Route path="/nsg/cyber-security" element={<CyberSecurityPage />} />
          <Route path="/nsg/business-intelligence" element={<BusinessIntelligencePage />} />
          <Route path="/nsg/editorial-services" element={<EditorialPage />} />
          <Route path="/nsg/executive-media" element={<ExecutiveMediaPage />} />
          <Route path="/nsg/public-affairs" element={<PublicAffairsPage />} />
          <Route path="/nsg/corporate-sponsorships" element={<CorporateSponsorshipsPage />} />

          { /* Tools  
          <Route path="/md/metadata" element={<MetaDataPage />} />
          */}

          { /* Additions */ }
          <Route path="/qrgenerator" element={<TestQRPage />} />
          <Route path='/thought-leadership' element={<ThoughtLeadership />} />
          { /* <Route path="/auth/ms" element={<TestAuthPage />} />*/ }

          <Route path="*" exact={true} element={<Container><h2>404 - Not Found</h2></Container>} />
        </Routes>
        </div>
        </Fade>
        { /*<div style={{minHeight: '120px'}} />*/}

        <Footer></Footer>
        { /**
        </Container>
         */}
        </MSAuthProvider>
      </Router>

      <Snackbar open={snackbarOpened} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning" sx={{ width: '100%' }}>
          Declined.  If you continue to utilize this site, you are consenting to the use of cookies.
        </Alert>
      </Snackbar>
      <Fade in={cookiebarFade} timeout={800}>

      <div>
      <CookieConsent
        hideOnAccept={false}
        enableDeclineButton
        location="bottom"
        buttonText="Accept All"
        declineButtonText="Decline"
        cookieName="gdprAccepted"
        style={{ background: "#fffe", fontSize: '0.9em', color: "#12232e"}}
        buttonStyle={{ backgroundColor: '#12232e', color: "#fff", fontSize: "13px" }}
        declineButtonStyle={{ backgroundColor: '#12232e66', color: "#222", fontSize: "13px" }}
        expires={150}
        onAccept={() => { setcookiebarFade(false) }}
        onDecline={() => {setsnackbarOpened(true)}}
      >
        We use cookies to enhance your browsing experience.  We value your privacy.  By clicking "Accept All", you consent to our use of cookies.
      </CookieConsent>
      </div>
      </Fade>
    </MuiThemeProvider>
  );
}

export default App;

